
body.home {

  #player-container {
    margin: 15px;

    #player-content {
      height: 0;
      padding-top: (9/16) * 100%;
      position: relative;

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: none;
      }
    }
  }

  main {
    padding-bottom: 0 !important;
  }


  /* ========================================================================
   * Boxes
   * ======================================================================== */

  div#boxes {
    margin-right: -8px;
    margin-left: -8px;

    a.edit {
      margin-right: 10px;
    }

    .grid-item {
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 16px;

      &.has-only-image {
        .grid-item-content {
          padding: 0;

          img {
            padding: 0;
            margin: 0;
          }
        }
      }

      &.linked {
        cursor: pointer;
      }

      .theme {
        height: 100%;
        //max-height: 100%;

        &.no-margin {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          img {
            display: none;
          }
        }
      }

      &.row-1 {
        height: $module_width_default;
      }

      &.row-2 {
        height: $module_width_default * 2 + 16;
      }

      &.col-1 {
        text-align: center;

        hr {
          margin-right: auto !important;
          margin-left: auto !important;
        }
      }

      .grid-item-content {
        padding: $module_padding_default 0;
        height: 100%;
        max-height: 100%;
        overflow: hidden;

        h2,
        .intro,
        img,
        .content {
          padding: 0 $module_padding_default;
        }

        .intro {
          font-family:'NeutraText-BookSC';
          text-transform: lowercase;
          line-height: 1.1;
        }

        hr {
          margin-left: $module_padding_default;
        }

        img {
          width: 100%;
          margin-bottom: 16px;
        }

        img.no-margin {
          padding: 0;
        }
      }

      &.newsletter {
        font-size: 13px;

        form.newsletter {
          padding: 15px 0 30px 0;

          input.email {
            background-color: #E7F0F4;
            color: #A1A1A1;
            border: none;
            font-family:'NeutraText-BookSC';
            text-transform: lowercase;
            font-size: 22px;
            height: 50px;
            width: 430px;
            padding: 0 20px;
            line-height: 1;
            margin-right: 40px;
          }

          input.submit {
            background-color: #E7F0F4;
            color: #8DB1C0;
            border: none;
            font-family:'NeutraText-BoldSC';
            text-transform: lowercase;
            font-size: 22px;
            height: 50px;
            padding: 0 30px;
            letter-spacing: .10em;
            line-height: 1;

          }
        }

        div#message {
          display: none;
          font-size: 18px;
          margin-bottom: 20px;
        }
      }

    }


    /* ---------------------------------
     * Themes
    */

    // Presets
    color: #666;

    h2 {
      color: #90B1C0;
      margin-top: 0;

      a {
        color: #90B1C0;
        text-decoration: none;
      }
    }

    hr {
      display: block;
      width: 60px;
      margin-top: 20px;
      margin-bottom: 20px;
      border-color: #999;
      border-width: 1px;
      padding: 0 !important;
    }

    // Theme 1: Green bg, blue header, grey text
    .theme1 {
      background-color: #C8E9E1;

      h2, h2 a, .intro {
        color: #90B1C0;
      }
    }

    // Theme 2: Red bg, blue header, grey text
    .theme2 {
      background-color: #F6E6E6;

      h2, h2 a, .intro {
        color: #90B1C0;
      }
    }

    // Theme 3: Purple bg, white header, grey text
    .theme3 {
      background-color: #BCBDE1;

      h2, h2 a, .intro {
        color: #fff;
      }

      hr {
        border-color: #fff;
      }
    }

    // Theme 4: Yellow bg, red header, grey text
    .theme4 {
      background-color: #F6ECBC;

      h2, h2 a, .intro {
        color: #C88B8B;
      }

      hr {
        border-color: #C88B8B;
      }
    }

    // Theme 5: Grey bg, blue header, grey text
    .theme5 {
      background-color: #FBFBFC;
      box-shadow: inset 0 1px 32px 0 rgba(116,187,190,0.1);

      h2, h2 a, .intro {
        color: #90B1C0;
      }
    }

  }

}


/* ==========================================================================

   Media Queries: Phone XS

   ========================================================================== */

@media (max-width: $screen-xs-max) {

  $delta: 0.7;

  $module_width: $module_width_default * $delta;
  $module_padding: $module_padding_default * $delta;
  $module_h2_size: $module_h2_size_default * $delta;
  $module_text_size: $module_text_size_default * $delta;

  body.home {

    a.edit {
      display: none;
    }


    /* ========================================================================
     * Boxes
     * ======================================================================== */

    div#boxes {
      font-size: $module_text_size;

      .grid-item {
        overflow: auto;

        .theme {
          height: 100%;

          &.no-margin {
            background-image: none !important;

            img {
              display: inline;
            }
          }
        }


        &.row-1 {
          height: auto;
        }

        &.row-2 {
          height: auto;
        }

        .grid-item-content {
          padding: $module_padding/2 0;


          h2,
          .intro,
          img,
          .content {
            padding: 0 $module_padding/2;
          }

          hr {
            margin-left: $module_padding/2;
          }

        }

        &.newsletter {
          font-size: 13px;

          form.newsletter {
            padding: 10px 0 20px 0;

            input {
              font-size: 18px;
              height: 35px;
              display: block;
              text-align: center;
            }

            input.email {
              display: block;
              width: auto;
              padding: 0 20px;
              margin-right: 40px;
              margin-bottom: 10px;
            }

            input.submit {
              padding: 0 30px;
              width: 100%;
            }
          }
        }

      }


      /* ---------------------------------
       * Themes
      */

      h2 {
        font-size: $module_h2_size;
        color: #90B1C0;

        a {
          color: #90B1C0;
          text-decoration: none;
        }
      }

    }

  }


}


/* ==========================================================================

   Media Queries: Tablet SM

   ========================================================================== */

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {


  // 0,62093520699645 from LG

  $delta: 0.62;

  $module_width: $module_width_default * 0.62;
  $module_padding: $module_padding_default * $delta;
  $module_h2_size: $module_h2_size_default * $delta;
  $module_text_size: $module_text_size_default * $delta;

  body.home {


    /* ========================================================================
     * Boxes
     * ======================================================================== */

    div#boxes {
      font-size: $module_text_size;

      .grid-item {

        &.row-1 {
          height: $module_width;
        }

        &.row-2 {
          height: $module_width * 2 + 16;
        }

        .grid-item-content {
          padding: $module_padding 0;

          h2,
          .intro,
          img,
          .content {
            padding: 0 $module_padding;
          }

          hr {
            margin-left: $module_padding;
          }

        }

        &.newsletter {
          font-size: 13px;

          form.newsletter {
            padding: 0 0 10px 0;

            input.email {
              font-size: 16px;
              height: 30px;
              width: 225px;
              padding: 0 20px;
              margin-right: 40px;
            }

            input.submit {
              font-size: 16px;
              height: 30px;
              padding: 0 30px;
            }
          }
        }

      }


      /* ---------------------------------
       * Themes
      */

      h2 {
        font-size: $module_h2_size;
        color: #90B1C0;

        a {
          color: #90B1C0;
          text-decoration: none;
        }
      }

    }

  }


}


/* ==========================================================================

   Media Queries: Desktop MD

   ========================================================================== */

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

  // 0,81948393036038 from LG

  $delta: 0.82;

  $module_width: $module_width_default * $delta;
  $module_padding: $module_padding_default * $delta;
  $module_h2_size: $module_h2_size_default * $delta;
  $module_text_size: $module_text_size_default * $delta;


  body.home {


    /* ========================================================================
     * Boxes
     * ======================================================================== */

    div#boxes {
      font-size: $module_text_size;

      .grid-item {

        &.row-1 {
          height: $module_width;
        }

        &.row-2 {
          height: $module_width * 2 + 16;
        }

        .grid-item-content {
          padding: $module_padding 0;

          h2,
          .intro,
          img,
          .content {
            padding: 0 $module_padding;
          }

          hr {
            margin-left: $module_padding;
          }

        }

        &.newsletter {
          font-size: 13px;

          form.newsletter {
            padding: 5px 0 20px 0;

            input.email {
              font-size: 22px;
              height: 50px;
              width: 290px;
              padding: 0 20px;
              margin-right: 40px;
            }

            input.submit {
              font-size: 22px;
              height: 50px;
              padding: 0 30px;
            }
          }
        }


      }


      /* ---------------------------------
       * Themes
      */

      h2 {
        font-size: $module_h2_size;
        color: #90B1C0;

        a {
          color: #90B1C0;
          text-decoration: none;
        }
      }

    }

  }

}


/* ==========================================================================

   Media Queries: Desktop LG

   ========================================================================== */

@media (min-width: $screen-lg-min) {

}
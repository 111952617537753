.p0 {
    padding: 0;
}
.item-image-width {
    width: 100% !important;
    margin: 0 !important;
}

.item-image-child img {
    width: 80% !important;
    float: left;
    margin-bottom: 1em;
}

.thumbnail-image-width {
    width: 20%;
    float: left;
    height: 544px;
    margin-bottom: 2em;
}

.thumbnail-image-width a {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 1em !important;
    padding-top: 0 !important;
}
#top-left {
    position: absolute;
    top: 44px;
    left: 50px;
}
.social-icon {
    width: 25px;
    height: 25px;
    margin: 5px;
    margin-top: 10px;
}
.no-mobile {
    display: block;
    @media (max-width: 767px) {
        display: none;
    }   
}


/****************************
*   Terms
****************************/
 #terms-overlay {
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    overflow: auto;
    font-family: NeutraText-Light;
    text-transform: none;

    #terms {
        max-width: 500px;
        margin: 100px auto;
        padding: 20px;
        color: #000;
        font-size: 16px;
        position: relative;

        h2 {
            padding: 0;
        }
        i.glyphicon {
            color: #000;
            font-size: 25px;
            position: absolute;
            z-index: 1000;
            top: 0;
            right: 0;
            margin: 0;
            cursor: pointer;
        }
    }
}
.terms-label {
    margin-left: 15px;
    font-size: 18px;
    line-height: 40px;
    a {
        text-decoration: underline;
        cursor: pointer;
        color: #8db1c0;
    }
}
.newsletter {
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2em;
    cursor: pointer;
    }

    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0; 
    top: 10px;
    width: 25px; 
    height: 25px;
    border: 1px solid #ddd7da;
    background: #e7f0f4;
    }

    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    top: 13px;
    left: 3px;
    transition: all .2s;
    display: inline-block;
    width: 19px;
    height: 19px;
    background: #90b1c0;
    }

    [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    }
    [type="checkbox"]:checked + label:after {
    opacity: 1;
    }

    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
    }
    label {
    margin: 0 !important;
    }

    label:hover:before {
    border: 2px solid #90b1c0 !important;
    }
}

/****************************
*   Mitt konto layout
****************************/
div.woocommerce {
    .woocommerce-MyAccount-content {
        width: 100% !important;
        float: none !important;
        p {
            width: 80%;
            float: none;
        }
        .woocommerce-Address-title h3 {
            font-size: 1.5rem;
        }
    }
    .woocommerce-MyAccount-navigation {
        width: 100% !important;
        margin-bottom: 3rem;
        text-align: center;
        padding-bottom: 2rem;
        border-bottom: 1px solid #eee;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            font-family: NeutraText-Light;
            font-size: 1.5rem;
            text-align: center;
            width: 100%;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 2px;
            li {
                margin-right: 2rem;
                margin-left: 2rem;
                margin-bottom: 1rem;
                display: inline-block;
                @media (max-width: 767px) {
                    display: block;
                }
                a {
                    font-family: NeutraText-BoldAlt;
                    color: #a8c7d4;
                    font-weight: 700;
                    font-size: 1.5rem;
                }
                a:hover {
                    color: #8db1c0 !important;
                    text-decoration: none;
                }
                a:focus {
                    color: #8db1c0 !important;
                    text-decoration: none;
                } 
            }
        }
    }

    .myaccount-content-editbtn {
        padding: 0.5rem 1rem 0.5rem 1rem;
        background-color: #f2acac;
        font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
        border-radius: 3px;
        
    }
    .myaccount-content-editbtn:hover {
        background-color: #e48787 !important;
        color: white;
        text-decoration: none;
    }
    .myaccount-name {
        text-transform: capitalize;
        color: #8db1c0;
        text-decoration: underline;
    }
    .is-active a {
        color: #8db1c0 !important;
    }
}

/*  ========================================================================== 
    Produktsidor
    ========================================================================== */
.woocommerce {
    div.product {
        div.woocommerce-product-gallery {
            width: 100%;
            @media (max-width: 767px) {
                min-height: 0;
            } 
            img.main-image {
                max-width: 440px;
                width: 75%;
                float: left;
                margin-right: 20px;
                @media (max-width: 767px) {
                    min-width: 100%;
                } 
            }
            div.small-img.woocommerce-product-gallery__image {
                display: flex;
                flex-direction: column;
                width: 20%;
                @media (max-width: 767px) {
                    display: inline-block;
                    width: 50%;
                } 
                img {
                    width: 65%;
                    @media (max-width: 767px) {
                        width: 100%;
                    } 
                }
            }
        }
        div.content-wrapper {
            display: inline-block;
            margin-top: 20px;
        }
    }
}



.page-header {
    @media (max-width: 767px) {
        text-align: center;
    }  
}
.sidebar {
    @media (max-width: 767px) {
        padding-left: 0px;    
    }
}

.loading-shade {
    opacity: 0.55;
}
.shop-text-center {
    text-align: center;
    margin-top: 4rem;
}

.m-a {
    margin: auto;
}

.load-more-btn {
    padding: 10px;
    border: 1px solid;
    text-decoration: none;
    display: block;
    margin-bottom: 5px;
    margin: auto;
    text-align: center;
    width: 200px;
    margin-top: 40px;
}

.desktop {
    display: block;
}

.mobile {
    display: none;
} 
@media (max-width: 770px) {
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
}
/* ==========================================================================
   Debug
   ========================================================================== */

pre {
  font-size: 9px;
}

/* ==========================================================================
   Vars
   ========================================================================== */

$button-color: #F2ACAC;


/* ==========================================================================
   Mixins
   ========================================================================== */

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    //position: absolute;
    //top: 0;
    //left: 0;
    //right: 0;
    //bottom: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

  }
}


/* ==========================================================================
   Fonts
   ========================================================================== */

@font-face {
  font-family:'NeutraText-LightSC';
  src: url('../fonts/NeutraText-LightSC_gdi.eot');
  src: url('../fonts/NeutraText-LightSC_gdi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NeutraText-LightSC_gdi.woff') format('woff'),
  url('../fonts/NeutraText-LightSC_gdi.ttf') format('truetype'),
  url('../fonts/NeutraText-LightSC_gdi.otf') format('opentype'),
  url('../fonts/NeutraText-LightSC_gdi.svg#NeutraText-LightSC') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}

@font-face {
  font-family:'NeutraText-Light';
  src: url('../fonts/NeutraText-Light_gdi.eot');
  src: url('../fonts/NeutraText-Light_gdi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NeutraText-Light_gdi.woff') format('woff'),
  url('../fonts/NeutraText-Light_gdi.ttf') format('truetype'),
  url('../fonts/NeutraText-Light_gdi.otf') format('opentype'),
  url('../fonts/NeutraText-Light_gdi.svg#NeutraText-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}

@font-face {
  font-family:'NeutraText-LightItalic';
  src: url('../fonts/NeutraText-LightItalic_gdi.eot');
  src: url('../fonts/NeutraText-LightItalic_gdi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NeutraText-LightItalic_gdi.woff') format('woff'),
  url('../fonts/NeutraText-LightItalic_gdi.ttf') format('truetype'),
  url('../fonts/NeutraText-LightItalic_gdi.otf') format('opentype'),
  url('../fonts/NeutraText-LightItalic_gdi.svg#NeutraText-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}

@font-face {
  font-family:'NeutraText-LightSC';
  src: url('../fonts/NeutraText-LightSC_gdi.eot');
  src: url('../fonts/NeutraText-LightSC_gdi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NeutraText-LightSC_gdi.woff') format('woff'),
  url('../fonts/NeutraText-LightSC_gdi.ttf') format('truetype'),
  url('../fonts/NeutraText-LightSC_gdi.otf') format('opentype'),
  url('../fonts/NeutraText-LightSC_gdi.svg#NeutraText-LightSC') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}

@font-face {
  font-family:'NeutraText-BookSC';
  src: url('../fonts/NeutraText-BookSC_gdi.eot');
  src: url('../fonts/NeutraText-BookSC_gdi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NeutraText-BookSC_gdi.woff') format('woff'),
  url('../fonts/NeutraText-BookSC_gdi.ttf') format('truetype'),
  url('../fonts/NeutraText-BookSC_gdi.otf') format('opentype'),
  url('../fonts/NeutraText-BookSC_gdi.svg#NeutraText-BookSC') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}

@font-face {
  font-family:'NeutraText-BoldSC';
  src: url('../fonts/NeutraText-BoldSC_gdi.eot');
  src: url('../fonts/NeutraText-BoldSC_gdi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NeutraText-BoldSC_gdi.woff') format('woff'),
  url('../fonts/NeutraText-BoldSC_gdi.ttf') format('truetype'),
  url('../fonts/NeutraText-BoldSC_gdi.otf') format('opentype'),
  url('../fonts/NeutraText-BoldSC_gdi.svg#NeutraText-BoldSC') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}

@font-face {
  font-family:'NeutraText-BoldAlt';
  src: url('../fonts/NeutraText-BoldAlt_gdi.eot');
  src: url('../fonts/NeutraText-BoldAlt_gdi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/NeutraText-BoldAlt_gdi.woff') format('woff'),
  url('../fonts/NeutraText-BoldAlt_gdi.ttf') format('truetype'),
  url('../fonts/NeutraText-BoldAlt_gdi.otf') format('opentype'),
  url('../fonts/NeutraText-BoldAlt_gdi.svg#NeutraText-BoldAlt') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-25CA;
}

@font-face {
  font-family: "sorsand";
  src:url("../fonts/sorsand.eot");
  src:url("../fonts/sorsand.eot?#iefix") format("embedded-opentype"),
  url("../fonts/sorsand.woff") format("woff"),
  url("../fonts/sorsand.ttf") format("truetype"),
  url("../fonts/sorsand.svg#sorsand") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "sorsand" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "sorsand" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bars:before {
  content: "\62";
}
.icon-diamond:before {
  content: "\61";
}



/* ==========================================================================
   Admin stuff
   ========================================================================== */

body.home {

  .edit {
    color: #000;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 3px 5px;
    background-color: rgba(255,255,255,0.4);
    text-decoration: none !important;
    border-radius: 2px;

    &:hover {
      background-color: rgba(255,255,255,0.8);
      //color: #fff;
    }
  }
}

.edit {
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}


/* ==========================================================================
   Global
   ========================================================================== */

h1, h2, h3,
h1 a, h2 a, h3 a {
  font-family:'NeutraText-BoldAlt';
  color: #8DB1C0;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-weight: 700;
}

a {
  transition-duration: 0.3s;
}

label {
  color: #000;
}

strong {
  //font-family:'NeutraText-BoldSC';
}

.woocommerce a.remove {
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size: 20px;
  line-height: 0.85;
  color: desaturate(red, 30%) !important;
}

.button {
  background-color: $button-color !important;
  color: #fff !important;

  &:hover {
    background-color: desaturate(darken($button-color, 10%), 10%) !important;
  }
}

#sb-infinite-scroll-load-more.finished {
  display: none !important;
}

body {
  font-family:'NeutraText-Light';
  background: #DEEAF0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;


  .wrap {

    > .row {
      background: #fff;

      #top {
        margin-bottom: 16px;
      }

      main {
        padding-bottom: 60px;
      }
    }

    // Blue side nav
    nav.side-nav {
      ul {
        list-style: none;
        padding-left: 0;

        li {

          &.active {
            a {
              background-color: #C3D9E4;
            }
          }
          a {
            background: #DEEAF0;
            font-family: NeutraText-BoldSC;
            font-size: 25px;
            letter-spacing: 0.15em;
            display: block;
            padding: 15px 10px;
            text-align: center;
            text-transform: lowercase;
            color: #fff;
            margin-bottom: 15px;
            text-decoration: none;

            &:hover, &.active {
              background-color: #C3D9E4;
            }
          }
        }
      }
    }

  }

}


/* ==========================================================================
   Chosen
   ========================================================================== */

.chosen-container {
  font-size: 18px;
}

.chosen-container-single .chosen-single {
  border-radius: 0;
  border-color: #B7BBBC;
  background: #fff;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  background: #fff;
}

.chosen-container-single .chosen-single div {
  width: 55px;
}

.chosen-container-single .chosen-single div b {
  background: url("../images/select.png") no-repeat center center !important;
  background-size: 15px 10px !important;
  transition: 0.2s;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
  transform: rotate(180deg);
}


/* ==========================================================================
   Mini cart/cart
   ========================================================================== */

dl.variation {
  font-size: 14px !important;

  dt, dd {
    line-height: 1 !important;
  }

  dd {
    margin-bottom: 0 !important;
    //line-height: 1;
  }
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
  font-size: 16px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.woocommerce.widget_shopping_cart {
  font-size: 18px;
  padding: 0 10px;

  .button {
    margin-top: 10px;
  }
}

.woocommerce.widget_shopping_cart .cart_list li {
  font-size: 16px;
}

.woocommerce.widget_shopping_cart .cart_list li.empty {
  padding-left: 0;
}

.woocommerce .widget_shopping_cart .total, .woocommerce.widget_shopping_cart .total {
  margin-top: 15px;
  margin-bottom: 5px
}



/* ==========================================================================

   Media Queries: Phone XS

   ========================================================================== */

@media (max-width: $screen-xs-max) {


}


/* ==========================================================================

   Media Queries: Tablet SM

   ========================================================================== */

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {


  /* ==========================================================================
   Chosen
   ========================================================================== */

  .chosen-container {
    font-size: 15px;
  }

  .chosen-container-single .chosen-single div {
    width: 40px;
  }

  .chosen-container-single .chosen-single div b {
    background-size: 18px 8px !important;
  }

}


/* ==========================================================================

   Media Queries: Desktop MD

   ========================================================================== */

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {


}


/* ==========================================================================

   Media Queries: Desktop LG

   ========================================================================== */

@media (min-width: $screen-lg-min) {

}


/* ==========================================================================

   Media Queries: Print

   ========================================================================== */

@media print {

}

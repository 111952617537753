// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #333;

$font-size-base:          22px;
$font-size-h1:            32px;
$font-size-h2:            25px;
$font-size-h3:            20px;

// Sorsand specific
$module_width_default: 369px;
$module_padding_default: 70px;
$module_h1_size_default: 32px;
$module_h2_size_default: 25px;
$module_text_size_default: 22px;

/* ==========================================================================
   Slideshow
   ========================================================================== */

div.slideshow {
  .item {
    background-color: #C3D9E4;
    @include aspect-ratio(1140, 450);

    h1 {
      margin-top: 0;
    }


    .content {
      padding: 0 5%;
    }

    .text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      line-height: 1.1;

      hr {
        margin: 20px 0;
        border-color: #999;
        border-width: 1px;
        width: 60px;
      }

    }

    .center {
      text-align: center;

      hr {
        margin-right: auto;
        margin-left: auto;
      }

    }

    .col-sm-8 {
      //border: solid 1px #f00;
    }

    .image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
        position: relative;
      }
    }

  }

  // Navigation dots
  .owl-dots {
    text-align: center;
    position: absolute;
    bottom: 20px;
    width: 100%;
    font-size: 19px;

    div {
      display: inline-block;
      cursor: pointer;
      color: #000;
      opacity: 0.15;
      margin: 0 5px;
      transition-duration: 0.5s;
      border-bottom: solid 3px transparent;

      &.active {
        color: #fff;
        opacity: 1;
        border-bottom: solid 3px #fff;
      }

      span {
      }
    }

  }
}


/* ==========================================================================

   Media Queries: Phone XS

   ========================================================================== */

@media (max-width: $screen-xs-max) {

  $delta: 0.7;

  $module_width: $module_width_default * $delta;
  $module_padding: $module_padding_default * $delta;
  $module_h1_size: $module_h1_size_default * $delta;
  $module_text_size: $module_text_size_default * $delta;

  div.slideshow {
    font-size: $module_text_size;

    .item {
      background-color: transparent;

      h1 {
        font-size: $module_h1_size;
      }

      &:before {
        padding-top: 0;
      }

      .content {
        padding: 0;
        position: static;
        top: auto;
        transform: none;
        width: 100%;
        margin: 0;
      }

      .text {
        position: static;
        top: auto;
        transform: none;

        hr {
          margin: 20px 0;
          border-color: #999;
          border-width: 1px;
          width: 60px;
        }

      }

      .center {
        text-align: left;

        hr {
          margin-right: 0;
          margin-left: 0;
        }

      }

      .visible-xs-block {
      }

      .image {
        position: static;
        top: auto;
        left: auto;
        transform: none;
        background-color: #C3D9E4;
        @include aspect-ratio(1, 1);
        margin-bottom: 20px;

        &.missing-image {
          background: #C3D9E4 url('/a/images/diamond-silhouette.png') no-repeat center center;
          background-size: 65%;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50% !important;
          transform: translate(-50%, -50%);
          //width: 100%;

          height: auto;
          width: auto;
          max-height: 80%;
          min-width: 80%;
          max-width: 80%;
          //position: relative;
          margin-right: auto;
          margin-left: auto;
        }

      }
    }

    // Navigation dots
    .owl-dots {
      position: static;
      bottom: auto;
      margin-bottom: 60px;

      div {
        color: #000;
        opacity: 0.15;

        &.active {
          color: #000;
          opacity: 0.35;
          border-bottom: solid 3px #000;
        }

        span {
        }
      }

    }
  }


}


/* ==========================================================================

   Media Queries: Tablet SM

   ========================================================================== */

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

  $delta: 0.8;

  $module_h1_size: $module_h1_size_default * $delta;
  $module_text_size: $module_text_size_default * $delta;


  div.slideshow {
    font-size: $module_text_size;

    .item {
      h1 {
        font-size: $module_h1_size;
      }
    }

    // Navigation dots
    .owl-dots {
      bottom: 15px;
      font-size: 16px;
    }
  }

}


/* ==========================================================================

   Media Queries: Desktop MD

   ========================================================================== */

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

  // 0,81948393036038 from LG

  $delta: 0.82;

  $module_h1_size: $module_h1_size_default * $delta;
  $module_text_size: $module_text_size_default * $delta;


  div.slideshow {
    font-size: $module_text_size;

    .item {
      h1 {
        font-size: $module_h1_size;
      }
    }
  }


}


/* ==========================================================================

   Media Queries: Desktop LG

   ========================================================================== */

@media (min-width: $screen-lg-min) {

}

/* ==========================================================================

   Media Queries: Larger than mobile

   ========================================================================== */

@media (min-width: $screen-sm-min) {

  div.slideshow {
    .item {
      // .item.fullsize
      &.fullsize {

        .content {
          margin: 0;
          padding: 0;

          .no-margin {
            margin: 0;
            padding: 0;
          }
        }

        .image {
          position: static;
          top: auto;
          left: auto;
          transform: none;

          img {
            height: auto;
            width: auto;
            max-height: 100%;
            max-width: 100%;
            position: relative;
          }
        }
      }
    }
  }
}
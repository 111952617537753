body.sidebar-primary {

  .wrap {
    margin-top: 16px;

    > .row {
      background: transparent;

      #top {
        background-color: #fff;
        padding-bottom: 16px;
        position: relative;
        top: -16px;
        margin-bottom: 0;
      }
    }

    main {
      background-color: #fff;
      //margin-right: -16px;
      padding-right: 50px;
      padding-left: 50px;
      padding-top: 30px;
      margin-bottom: 16px;

    }

    aside {

      > div {
        background-color: #fff;
        //margin-left: 16px;
        padding-right: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        margin-right: -16px;

        section {
          padding-top: 1px;
        }

      }

      ul.products {
        list-style: none;

        li.item {
          float: none;
        }
      }

    }
  }
}

/* ==========================================================================
  Archive
  ========================================================================== */

body.archive.product {
  main {
    background-color: #deeaf0;
    padding-right: 0;
    padding-left: 0;

    // Categories nav
    nav#cat-nav {
      background-color: #fff;
      padding-bottom: 20px;
      padding-top: 20px;

      ul {
        font-family: NeutraText-LightSC;
        text-align: center;
        padding-left: 0;
        font-size: 18px;
        letter-spacing: 0.15em;
        text-transform: lowercase;
        margin-bottom: 6px;

        li {
          display: inline-block;

          &.active {
            background-color: #D6F3ED;

            ul {
              display: block;
            }
          }

          a {
            display: inline-block;
            padding: 2px 15px 6px;
            margin: 0 10px;
          }

          ul {
            display: none;
            position: absolute;
            width: 100%;
            //top: 40px;
            left: 0;
            //float: left;
          }
        }

        &.sub-nav {
          display: none;

          &.active {
            display: block;
          }

          li.active {
            background-color: transparent;
            font-family: NeutraText-BookSC;
          }
        }
      }

    }

    // Price slider widget
    .price-slider-wrapper {
      position: relative;
      background-color: #fff;
      margin-top: 16px;
      font-family: NeutraText-LightSC;
      font-size: 18px;
      letter-spacing: .15em;
      text-transform: lowercase;
      padding: 30px;
      @media (max-width: 770px) {
        padding: 40px 30px;
      }

      ul.berocket_aapf_widget {
        margin-bottom: 0;
        padding-left: 0;
        margin-right: 0;
      }
    }

    // Loading screen and stuff
    .berocket_aapf_widget_loading {
      background-color: transparent;
    }

    ul.hide_products {
      li {
        display: none;
      }
    }

    // Slider
    .ui-slider.ui-widget-content, .berocket_filter_price_slider.ui-widget-content {
      height: 1px;
      background-color: #DFDFDF;
      margin-right: 150px;
      margin-left: 120px;
      @media (max-width: 770px) {
        width: 100%;
        top: 15px;
        margin-right: 0px;
        margin-left: 0px;
        }
      .ui-slider-range{
        background-color: transparent;
      }
      .ui-slider-handle {
        background: url(../images/slider-right.png);
        width: 33px;
        height: 30px;
        top: -14px;
        margin-left: -33px;
        outline: none !important;
        border: 0;
        margin: 0;
        &:first-of-type {
          background: url(../images/slider-left.png);
          margin-left: 0;
        }
      }
    }

    // Slider line active background


    // Slider input containers
    .berocket_aapf_widget li.slider span.left, .berocket_aapf_widget li.slider span.right {
      display: none;
    }

    // Custom slider price display
    .custom-price {
      top: 17px;
      position: absolute;
      @media (max-width: 770px) {
        top: 5px;
      }

      &.left-price {
        left: 30px;
      }

      &.right-price {
        right: 30px;
      }
    }

  }
}

// Product list
ul.products {
  margin: auto !important;
  padding: 0;

  li {
    padding-top: 16px;
    padding-left: 8px;
    padding-right: 8px;

    div.product-container {
      background-color: #fff;
      text-align: center;
      position: relative;
      cursor: pointer;

      div.product-bg {
        position: absolute;
        z-index: 1;
        height: 20px;
        bottom: 0 !important;
        left: 0;
        right: 0;
        //background-color: #fcc;
        transition-timing-function: ease;
        transition-duration: .3s;

        &.active {
          //height: 100%;
          //opacity: .5;
          //background-color: desaturate(darken(#fcc, 10%), 10%);
        }
      }

      div.product-content {
        position: relative;
        z-index: 2;

        div.product-images {
          position: relative;

          div {
            width: 300px;
            //height: 300px;
            max-width: 50%;
            margin-right: auto;
            margin-left: auto;
          }

          div.product-image-secondary {
            transition-timing-function: ease;
            transition-duration: .6s;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            &.active {
              opacity: 1;
            }
          }
        }

        img {
        }

        a {
          text-decoration: none !important;
        }

        hr {
          width: 20%;
          border-color: #979797;
        }

        a.add_to_cart_button {
          display: none;
        }
      }
    }

    // li.featured
    &.featured {
      div.product-container {
        div.product-content {
          div.product-images {
            div {
              max-width: 100%;
              width: 600px;
            }
            //margin-bottom: 25px; //HACK when every box is featured (all text is hidden)
          }
        }
      }
    }
  }
}

// Sidebar product list
aside.sidebar {
  ul.products {
    li {
      width: 100%;

      div.product-container {

        div.product-content {
          div.product-images {
            //width: 100%;
            //height: 300px;
            max-width: 100%;

            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

/* ==========================================================================
  Single
  ========================================================================== */

body.single.product.woocommerce-page
{
  div.product {

    h1 {
      font-family:'NeutraText-BoldSC';
      font-size: 45px;
      text-transform: lowercase;
    }

    div#short-description {
      font-size: 16px;
    }

    div#description {
      margin-top: 25px;

      p {
        margin-bottom: 3px;
      }
    }

    div.product_meta {
      font-family:'NeutraText-BookSC';
      font-size: 22px;
      color: #B7BBBC;
      text-transform: lowercase;

      a {
        color: #8DB1C0;
      }
    }

    div.images {
      float: none;
    }

    // From price
    p.price {
      font-family:'NeutraText-BoldSC';
      color: #8DB1C0;
      text-transform: lowercase;
      font-size: 25px;
      letter-spacing: 0.15em;
      margin-top: 20px;
    }

    // Variations selects
    ul.variations {
      list-style: none;
      padding: 0;
      margin-top: 40px;

      li {
        margin-bottom: 20px;

        label {
          font-family:'NeutraText-LightSC';
          color: #1D1D1D;
          font-size: 11px;
          line-height: 1;
          margin-bottom: 0;
          display: block;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          padding-bottom: 5px;
          font-weight: 300;

        }

        select {
          width: 100%;
        }
      }
    }

    // Variation price
    span.price {
      font-family:'NeutraText-BoldSC';
      color: #F2ACAC;
      text-transform: lowercase;
      font-size: 35px;
      letter-spacing: 0.15em;

    }

    .product-addon-gravyrtext {
      display: none;
    }

    div.related.products {
      border-top: solid 1px #D0DFE5;
      padding-top: 50px;
      margin-top: 40px;
    }
  }
}

/* ==========================================================================
  Modules
  ========================================================================== */

body.page-modules {

  .mod {
    margin-top: 30px;
    margin-bottom: 30px;

    &.center {
      text-align: center;
    }

    &.twocols {
      column-count: 2;
      column-gap: 30px;
    }

    &.full {

      img {
        max-width: none;
        width: 100%;
      }
    }

    &.full-margin {

      padding-right: 50px;
      padding-left: 50px;

      img {

      }
    }
  }
}


/* ==========================================================================
  Retailer
  ========================================================================== */

#map_canvas {
  top: -30px;
}

body.page-retailer {
  main {
    div#main-content {
      margin-top: 30px;
      line-height: 1.2;

      .retailer-item {
        margin-bottom: 60px;

        strong {
          //font-family:'NeutraText-BookSC';
        }

        img {
          margin-bottom: 20px;
        }

        a {
          color: #A3BECA;
          font-weight: bold;
        }
      }

    }
  }
}

/* ==========================================================================
  Sub menu
  ========================================================================== */

body.page-retailer,
body.page-subpage-submenu,
body.page-subpage {

  main {
    padding-top: 30px;

    aside {
      margin-top: 30px;
      margin-left: 20px;
    }

    div#main-content {
      margin-left: 40px;
    }
  }
}


/* ==========================================================================
  Sidebar with products
  ========================================================================== */

body.page-subpage-sidebar {

  aside {
    padding: 0;

    > div {
      background-color: transparent !important;

      ul.products {
        li {
          padding-top: 0;
          padding-bottom: 16px;
        }
      }

    }
  }

  main {
  }

}


/* ==========================================================================
  Cart
  ========================================================================== */

body.woocommerce-cart,
body.woocommerce-checkout {

  main {
    padding-right: 80px;
    padding-left: 80px;
  }
}




/* ==========================================================================

   Media Queries: Phone XS

   ========================================================================== */

@media (max-width: $screen-xs-max) {

  body.archive.product {
    main {

      // Price slider widget
      div.berocket_aapf_widget-wrapper {
        display: none;
      }
    }
  }
}

/* ==========================================================================

   Media Queries: Tablet SM

   ========================================================================== */

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
}


/* ==========================================================================

   Media Queries: Desktop MD

   ========================================================================== */

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {


}


/* ==========================================================================

   Media Queries: Desktop LG

   ========================================================================== */

@media (min-width: $screen-lg-min) {

}


/* ==========================================================================

   Media Queries: Print

   ========================================================================== */

@media print {

}
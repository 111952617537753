header.banner {
  .container {
    background: #fff;
    position: relative;

    // Logo
    a.brand {
      display: block !important;
      overflow: hidden;
      width: 264px;
      height: 51px;
      background: url(../images/logo.png) no-repeat left top;
      background-size: 264px 51px;
      text-indent: -9999px;
      margin: 45px auto 20px;
      position: relative;
    }

    // Nav
    nav {
      margin-bottom: 15px;
      font-family: NeutraText-LightSC;
      font-size: 18px;
      line-height: 1;
      color: #000000;
      letter-spacing: 0.15em;
      text-transform: lowercase;


      ul.nav {
        text-align: center;

        li {
          display: inline-block;

          &.active {
            font-family: NeutraText-BookSC;
          }

          a {
            color: #000;
          }
        }
      }
    }

    div#top-right {
        position: absolute;
        top: 44px;
        right: 50px;

      a#retailer-link {
        float: left;
        clear: none;
        background: transparent url('/a/images/pin.png') no-repeat center center;
        background-size: 18px 29px;
        width: 18px;
        height: 29px;
        text-indent: -9999px;
        margin-right: 20px;
        margin-top: 7px;
        opacity: 0.4;

        &:hover {
          opacity: 0.7;
        }
      }


      div#top-cart {
        float: left;
        clear: none;
        font-size: 14px;
        background: transparent url('/a/images/cart-bag.png') no-repeat right center;
        background-size: 26px 30px;
        line-height: 1;
        padding: 12px 0 10px 0;
        margin-right: 26px;

        a {
          color: #8B8B8B;
          padding: 10px 35px 10px 0;
          text-decoration: none;
        }
      }

      a#flag {
        position: relative;
        top: 2px;
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }

    }
  }
}


/* ==========================================================================

   Media Queries: Phone XS

   ========================================================================== */

@media (max-width: $screen-xs-max) {

  header.banner {

    .container {
      padding-bottom: 20px;

      // Logo
      a.brand {
        margin: 75px auto 10px;
      }

      span.icon-bars {
        text-align: center;
        cursor: pointer;
        font-size: 26px;
        color: #666;
        line-height: 1;
      }

      // Nav
      nav {
        margin-bottom: 0;
        display: none;

        ul.nav {
          li {
            display: block;
          }
        }
      }

      div#top-right {
        position: absolute;
        top: 16px;
        left: 0;
        right: 0;
        text-align: center;

        a#retailer-link {
          float: none;
          display: inline-block;
        }

        div#top-cart {
          float: none;
          display: inline-block;
        }

      }
    }
  }

}


/* ==========================================================================

   Media Queries: Tablet SM

   ========================================================================== */

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {


}


/* ==========================================================================

   Media Queries: Desktop MD

   ========================================================================== */

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {


}


/* ==========================================================================

   Media Queries: Desktop LG

   ========================================================================== */

@media (min-width: $screen-lg-min) {

}


/* ==========================================================================

   Media Queries: Print

   ========================================================================== */

@media print {

}
